<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $tc("app.insights", 2) }}</h1>
    <div class="row q-mb-xl">
      <div class="col-12">
        <hy-filter @change="fetch($event)" />
      </div>
    </div>
    <div class="row q-mb-xl" v-if="!loading && !data">
      <div class="col-12 text-center">
        {{ $t('app.noData') }}
      </div>
    </div>
    <div class="row q-mb-xl">
      <div class="col-12 col-sm-6 col-lg-3 q-px-xl">
        <hy-kpi-box kpi="other" :data="null" :loading="loading" :injectedTitle="$t('reports.sentiment')" :injectedValue="data && data.totals.sentiment !== null ? resolveSentiment(data.totals.sentiment) : '-'"/>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 q-px-xl">
        <hy-kpi-box kpi="other" :data="null" :loading="loading" :injectedTitle="$t('reports.reviews')" :injectedValue="data ? data.totals.reviews.toLocaleString($i18n.locale) : ''"/>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 q-px-xl">
        <hy-kpi-box kpi="other" :data="null" :loading="loading" :injectedTitle="$t('reports.statementsPositiveHeadline')" :injectedValue="data ? `${$tc('reports.statement', data.totals.statements.positive)} ${$tc('reports.category', data.totals.categories.positive)}` : ''"/>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 q-px-xl">
        <hy-kpi-box kpi="other" :data="null" :loading="loading" :injectedTitle="$t('reports.statementsNegativeHeadline')" :injectedValue="data ? `${$tc('reports.statement', data.totals.statements.negative)} ${$tc('reports.category', data.totals.categories.negative)}` : ''"/>
      </div>
    </div>
    <div class="row q-mb-xl gt-sm">
      <div class="col-12 q-px-xl">
        <h2 class="text-center">{{ $t('reports.sentimentDevelopment') }}</h2>
        <hy-sentiment-chart :loading="loading" :data="data?.development.sentiment || []" type="areaspline"/>
      </div>
    </div>
    <div class="row q-mb-xl gt-sm">
      <div class="col-6 q-px-xl">
        <h2 class="text-center">{{ $t('reports.statementsPositiveHeadline') }}</h2>
        <hy-item-list :loading="loading" :items="data?.categories.positive || []" type="positive" :trends="data?.trends.positive || null" :filter="filter" />
      </div>
      <div class="col-6 q-px-xl gt-sm">
        <h2 class="text-center">{{ $t('reports.statementsNegativeHeadline') }}</h2>
        <hy-item-list :loading="loading" :items="data?.categories.negative || []" type="negative" :trends="data?.trends.negative || null" :filter="filter" />
      </div>
    </div>

    <div class="row q-mb-xl">
      <div class="col-12 lt-md text-center text-info q-mb-xl">
        {{ $t('reports.lowRes') }}
      </div>
      <!-- <div class="col-12">
        <h2 class="text-center">{{ $t('reports.summary') }}</h2>
        <p class="text-center" v-if="summary === 'insufficient-data'">{{ $t('reports.insufficientData') }}</p>
        <p class="text-justify" v-else v-html="summary.replaceAll('\n', '<br>')"></p>
      </div> -->
    </div>
  </section>
</template>

<script lang="ts">
import type { Insights, ReportFilter } from '@/types';
import { defineComponent } from 'vue';
import HyFilter from '@/components/Filter/ReportForm.vue';
import { useReportStore } from '@/stores/report';
import HyItemList from '@/components/Report/ItemList.vue';
import HySentimentMixin from '@/mixins/Sentiment.vue';
import HySentimentChart from '@/components/Report/SentimentChart.vue';
import HyKpiBox from '../components/Dashboard/KpiBox.vue'

export default defineComponent({
  mixins: [HySentimentMixin],
  components: {
    HyFilter,
    HyItemList,
    HySentimentChart,
    HyKpiBox
  },
  data() {
    return {
      reportStore: useReportStore(),
      filter: null as ReportFilter | null,
      loading: true,
      data: null as Insights | null,
      summary: '',
      
    }
  },
  methods: {
    async fetch(filter: ReportFilter) {

      this.filter = filter

      this.loading = true
      this.reportStore.fetchInsights(filter, this.$i18n.locale)
        .then((data) => {
          this.data = data
          // this.reportStore.generateSummary(filter, this.$i18n.locale, data).then(summary => {
          //   this.summary = summary
          // })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  watch: {
    '$i18n.locale'() {
      this.fetch(this.filter as ReportFilter)
    }
  }

})
</script>