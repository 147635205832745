<template>
  <q-dialog v-model="doShow" @hide="translationStore.reset('all'); doShow = false">
    <q-card style="width: 1000px; max-width: 80vw;">
      <q-card-section>
        <div class="row q-px-md">
          <div class="col-8">
            <h2 class="q-mb-none">{{ $t('reports.reviews') }}</h2>
            <div class="text-hy-secondary" v-if="type === 'positive'">{{ $t('reports.statementsPositiveHeadline') }} {{ $t('reports.about') }} "{{ item?.title }}"</div>
            <div class="text-hy-secondary" v-if="type === 'negative'">{{ $t('reports.statementsNegativeHeadline') }} {{ $t('reports.about') }} "{{ item?.title }}"</div>
            <div class="text-hy-secondary" v-if="type === 'date'">{{ $t('reports.statementsDateHeadline') }} {{ item?.title }}</div>
          </div>
          <div class="col-4 q-pt-md ">
            <q-input filled v-model="search" :label="$t('reports.searchStatements')" color="hy-primary"
              @keyup.enter="fetch()">
              <!-- <template v-slot:prepend>
                <q-icon name="search" />
              </template> -->
              <template v-slot:after>
                <q-btn round size="sm" color="hy-primary" icon="search" @click.stop="fetch()" />
              </template>
            </q-input>
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-scroll-area style="height: 70vh">
          <q-list separator>
            <p class="text-hy-secondary text-center" v-if="!data?.reviews?.length">{{ $t('reports.noReviews') }}</p>
            <q-item v-for="(review, index) in data?.reviews">
              <q-item-section>
                <div class="row">
                  <div class="col-8" style="position:relative">
                    <div style="line-height: 1.5rem; vertical-align: top;">
                      <div style="height: 1.5rem; width: 1.5rem;  display:inline-block;vertical-align: middle; background-size: contain; opacity: .65" class="q-mr-xs"
                            :style="`background-image: url(/images/platform/${review?.platformName.replace('-readonly', '')}-mono--${$q.dark.isActive ? 'dark' : 'light'}.png)`">
                      </div>
                      <div style="display:inline-block;vertical-align: middle;" class="q-mr-md">
                        <span class="q-timeline__subtitle q-ml-md">{{ review.author.name }} {{ $t('app.at_time') }} {{ $d(new Date(review.datePublished), 'short') }}</span>
                      </div>
                      <div style="display:inline-block;vertical-align: top;" class="">
                        <hy-rating :rating="review.rating" :platform="review.platformName" />
                      </div>
                      <q-btn
                      v-if="translationStore.isAvailable(`review-${index}`, review, $i18n.locale)"
                        style="position: absolute; right: 18px; top: 0px;" 
                        size="sm"
                        round flat
                        icon="translate" 
                        color="hy-primary" 
                        class="q-ml-md"
                        @click="translationStore.toggle(`${$.uid}-review-${index}`, $i18n.locale, review.message)">
                        <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('interactions.translationShow') }}</q-tooltip>
                      </q-btn>
                    </div>
                    <div v-if="review.message" v-html="review.message.trim()" class="q-pr-md"
                      style="white-space: pre-line;font-size: 16px"></div>
                    <div v-else class="q-pr-md text-hy-secondary" style="font-size: 16px">{{ $t('interactions.noMessage') }}</div>
                    <div class="q-pr-md"  >
                      <hy-translation :text="translationStore.text[`${$.uid}-review-${index}`] || ''" :open="translationStore.showing[`${$.uid}-review-${index}`] || false" :loading="translationStore.loading[`${$.uid}-review-${index}`] || false" />
                    </div>  
                  </div>
                  <div class="col-4" style="font-size: 16px">
                    <span class="text-hy-primary" style="font-weight: 500">{{ $t('reports.categories') }}</span><br>
                    <span v-for="(item, index) in data?.statementMap[review._id] ? data?.statementMap[review._id].categories : []">
                      <span style="white-space: nowrap;">
                        <q-icon :name="item.sentiment === 'positive' ? 'thumb_up' : 'thumb_down'" />&nbsp;{{ item.text
                        }}
                      </span>
                      <span v-if="index < (data?.statementMap[review._id].categories.length as number) - 1"><br></span>
                    </span>
                    <span v-if="!data?.statementMap[review._id]">-</span>
                    <br>
                    <span class="text-hy-primary" style="font-weight: 500">{{ $t('reports.statements') }}</span><br>
                    <span v-for="(item, index) in data?.statementMap[review._id] ? data?.statementMap[review._id].statements : []">
                      <span style="white-space: nowrap;">
                        <q-icon :name="item.sentiment === 'positive' ? 'thumb_up' : 'thumb_down'" />&nbsp;{{ item.text
                        }}
                      </span>
                      <span v-if="index < (data?.statementMap[review._id].statements.length as number) - 1"><br></span>
                    </span>
                    <span v-if="!data?.statementMap[review._id]">-</span>

                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn flat :label="$t('app.close')" color="hy-primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { useTranslationStore } from '@/stores/translation';
import { InsightsReviewResponse, StatementsItem } from '@/types';
import { defineComponent, PropType } from 'vue';
import HyRating from '@/components/Interactions/Rating.vue';
import { useReportStore } from '@/stores/report';
import HyTranslation from '@/components/Interactions/Translation.vue'

export default defineComponent({
  components: {
    HyRating,
    HyTranslation
  },
  props: {
    item: {
      type: [Object, null] as PropType<null | StatementsItem>,
      required: true
    },
    type: {
      type: String as PropType<'positive' | 'negative' | 'date'>,
      required: true
    }
  },
  emits: ['hide'],
  data() {
    return {
      translationStore: useTranslationStore(),
      data: null as InsightsReviewResponse | null,
      search: '',
    }
  },
  computed: {
    doShow: {
      get() {
        return this.item !== null
      },
      set() {
        this.search = ''
        this.$emit('hide')
      }
    }
  },
  methods: {
    async fetch() {
      if (this.item) {
        this.data = await useReportStore().fetchReviews(this.item.reviewIds, this.$i18n.locale, this.search)
      }
    }
  },
  watch: {
    item: {
      async handler() {
        this.fetch()
      }
    }
  }
})
</script>