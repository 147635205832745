<template>
  <div :style="`height: ${scrollHeight}; position:relative`">
    <q-inner-loading :showing="loading" style="z-index:99" :style="`height: ${scrollHeight}`" />
    <q-scroll-area :style="`height: ${scrollHeight}; min-height: 200px`" class="q-pr-md">

      <!-- <div class="col-12 col-sm-4 col-lg-3">
        <q-btn color="hy-primary" v-if="review.platformName === 'google'" icon="refresh" class="full-width ellipsis" :label="$t('interactions.btnSync')"
          @click="syncReview()" :disable="loading" />
      </div> -->
      <q-timeline v-if="review" color="hy-primary" class="q-mx-md q-pr-xl">
        <!-- BEGIN Review -->
        <q-timeline-entry :icon="'star'">
          <template v-slot:subtitle>
            <span class="text-hy-default">
              {{ review.author.name }} {{ $t('interactions.on') }}
              {{ reviewTenantName }}, {{ $d(new Date(review.datePublished), 'long') }}
            </span>
            
          </template>

          <template v-slot:title>
            <hy-rating :rating="review.rating" :platform="review.platformName" />
            <q-btn 
              size="sm"
              round flat
              icon="translate" 
              color="hy-primary" 
              class="q-ml-md"
              v-if="translationStore.isAvailable('review', review, $i18n.locale)"
              @click="translationStore.toggle('review', $i18n.locale, review.message)">
              <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('interactions.translationShow') }}</q-tooltip>
            </q-btn>
          </template>
          <div v-if="review.message" v-html="review.message" style="white-space: pre-line;" class="text-hy-default"></div>
          <div v-else class="hy-info-text">{{ $t('interactions.noMessage') }}</div>
          <hy-translation :text="translationStore.text.review || ''" :open="translationStore.showing.review || false" :loading="translationStore.loading.review || false" />
        </q-timeline-entry>
        <!-- END Review -->
        <!-- BEGIN Responses -->
        <q-timeline-entry icon="reply" color="hy-primary"
          v-for="response in review.responses.filter((r) => r.state !== 'hyoban-draft')">

          <template v-slot:subtitle>{{ reviewSubTitle }}</template>

          <template v-slot:title>
            {{ response.state === 'hyoban-draft' ? $t('app.draft') : $t('app.response') }}
            <q-btn flat v-if="review.platformName === 'google'" round color="hy-primary" size="sm" icon="delete" @click="deleteResponse(response.externalId)">
              <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('interactions.deleteResponse') }}</q-tooltip>
            </q-btn>
            <q-btn 
              size="sm"
              round flat
              icon="translate" 
              color="hy-primary" 
              class="q-ml-md"
              v-if="translationStore.isAvailable('response', review, $i18n.locale)"
              @click="translationStore.toggle('response', $i18n.locale, review.responses[0].message)">
              <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('interactions.translationShow') }}</q-tooltip>
            </q-btn>
          </template>
          <div v-html="response.message" v-if="response.state !== 'hyoban-draft'" style="white-space: pre-line;"
            class="text-hy-default">
          </div>
          <hy-translation :text="translationStore.text.response || 'false'" :open="translationStore.showing.response || false" :loading="translationStore.loading.response || false" />
        </q-timeline-entry>
        <!-- END Responses -->
      </q-timeline>
      <div v-if="showForm">
        <q-input filled type="textarea" ref="input" v-model="responseDraft"
          :label="reviewIsLocked ? $t('interactions.jobInProgress') : $t('interactions.compose')" color="hy-primary"
          :disable="loading || reviewIsLocked" :hint="hint" autogrow @keyup="translationStore.reset('draft')">
          <template v-slot:append >
            <div class="q-mt-md">
              <div>
                <q-btn size="sm" round flat icon="sentiment_satisfied" color="hy-primary" class="q-ml-md"
                  :disable="loading || reviewIsLocked">
                  <q-menu>
                    <EmojiPicker :native="true" @select="onSelectEmoji($event)" hide-group-names disable-skin-tones
                      hide-search :theme="$q.dark.isActive ? 'dark' : 'light'" />
                  </q-menu>
                </q-btn>
              </div>
              <div>
                <q-btn 
                  size="sm"
                  round flat
                  icon="translate" 
                  color="hy-primary" 
                  class="q-ml-md"
                  v-if="translationStore.isAvailable('draft', review, $i18n.locale)"
                  @click="translationStore.toggle('draft', $i18n.locale, responseDraft)">
                  <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('interactions.translationShow') }}</q-tooltip>
                </q-btn>
              </div>
            </div>
            

          </template>
        </q-input>
        <hy-translation :text="translationStore.text.draft || ''" :open="translationStore.showing.draft || false" :loading="translationStore.loading.draft || false" />
        <div class="row q-col-gutter-md q-mt-xs">
          <div class="col-12 col-sm-4 col-lg-3 offset-lg-1">
            <q-btn color="hy-primary" class="full-width ellipsis" :label="$t('interactions.btnGenerate')"
              @click="generateResponse()" :disable="loading || reviewIsLocked" />
          </div>
          <div class="col-12 col-sm-4 col-lg-3">
            <q-btn color="hy-primary" class="full-width ellipsis" :label="$t('interactions.btnSave')"
              @click="saveResponse()" :disable="!responseDraft || loading || reviewIsLocked" />
          </div>
          <div class="col-12 col-sm-4 col-lg-3">
            <HyPublishButton 
              :platform="review.platformName" 
              :content="responseDraft" 
              :disable="!responseDraft || loading || reviewIsLocked" 
              @publish="publishResponse"
              />
          </div>
        </div>
      </div>
      <p v-else class="text-center hy-info-text">
        {{ $t(`interactions.disabled.${review.platformName}`) }}
      </p>
    </q-scroll-area>
  </div>
</template>
<script lang="ts">
import { Review, Response } from '@/types';
import { PropType, defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useDataStore } from '@/stores/data';
import HyPublishButton from './PublishButton.vue';
import EmojiPicker, { EmojiExt } from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import '@/styles/emoji-picker.sass'
import { useTranslationStore } from '@/stores/translation';
import HyRating from '@/components/Interactions/Rating.vue'
import HyTranslation from '@/components/Interactions/Translation.vue'


export type ReviewJobName = "draftResponsePublish" | "deleteResponse";

export const hasJob = (review: Review, jobName: ReviewJobName) =>
  !!(
    review.jobs?.[jobName]?.job &&
    review.jobs?.[jobName].job.stateHistory[
      review.jobs?.[jobName].job.stateHistory.length - 1
    ].stateName === "queued"
  );

export default defineComponent({
  components: {
    EmojiPicker,
    HyPublishButton,
    HyRating,
    HyTranslation
  },
  props: {
    review: {
      type: Object as PropType<Review>,
      required: true
    },
    scrollHeight: {
      type: String as PropType<String>,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      responseDraft: '',
      isDirty: false,
      dataStore: useDataStore(),
      translationStore: useTranslationStore(),
    }
  },
  methods: {
    // async translateDraft() {
    //   const dialog = this.$q.dialog({
    //     title: this.$t('interactions.translateDraft'),
    //     color: 'hy-primary',
    //     progress: true, 
    //     persistent: false, 
    //     ok: true,
    //     style: 'width: 50%',
    //   }).onDismiss(() => {
    //     this.translationStore.reset('draft')
    //   })

    //   await this.translationStore.generate('draft', this.responseDraft, this.$i18n.locale)

    //   dialog.update({
    //     message: this.translationStore.text.draft,
    //     progress: false,
    //     html: true
    //   })
    // },
    async syncReview() {
      this.$emit('update:loading', true)
      this.loading = true
      const res = await useDataStore().syncSingle(this.review._id as string)
      this.$emit('update:review', res.review)
      if (res.hasChanged) {
        console.log("REVIEW HAS CHANGED ON PLATFORM")
      }
      this.dataStore.addToastMessage({
        severity: "positive",
        caption: this.$t("interactions.synced"),
        message: this.$t("app.success"),
      })
      this.loading = false
      this.$emit('update:loading', false)
    },
    async deleteResponse(externalId: string) {
      this.$q.dialog({
        class: 'hy-dialog',
        title: this.$t('app.confirm'),
        message: ['google', 'facebook'].includes(this.review.platformName) ? this.$t('interactions.delete.api') : this.$t('interactions.delete.platform'),
        persistent: true,
        color: 'hy-primary',
        cancel: this.$t('app.cancel'),
        ok: this.$t('app.confirm'),
      }).onOk(async () => {

        if (['google', 'facebook'].includes(this.review.platformName)) {
          this.$emit('update:loading', true)
          this.loading = true
          try {
            const res = await useDataStore().deleteResponse(this.review._id as string, externalId)
            this.$emit('update:review', res)
            this.isDirty = false
            this.dataStore.addToastMessage({
              severity: "positive",
              caption: this.$t("interactions.deleted"),
              message: this.$t("app.success"),
            })
          } catch (e) {
            console.error(e)
            this.dataStore.addToastMessage({
              severity: "negative",
              caption: this.$t("app.error"),
              message: this.$t("app.errorServer"),
            })
          } finally {
            this.loading = false
          }
        } else {
          window.open('https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/reviews.html')
        }

        
      })

    },
    async saveResponse() {
      this.$emit('update:loading', true)
      this.loading = true

      try {
        const res = await useDataStore().saveResponse(this.review._id as string, this.responseDraft)
        this.$emit('update:review', res)
        this.isDirty = false

        this.dataStore.addToastMessage({
          severity: "positive",
          caption: this.$t("interactions.saved"),
          message: this.$t("app.success"),
        })

      } catch (e) {
        console.error(e)
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
      } finally {
        this.loading = false
      }
    },
    async generateResponse() {
      this.$emit('update:loading', true)
      this.loading = true

      try {
        this.responseDraft = await useDataStore().generateResponse(this.review.tenantId, this.review._id)
        this.isDirty = true
        this.dataStore.addToastMessage({
          severity: "positive",
          caption: this.$t("interactions.generated"),
          message: this.$t("app.success"),
        })
      } catch (e) {
        console.error(e)
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
      } finally {
        this.loading = false
      }
    },
    async publishResponse() {
      this.$emit('update:loading', true)
      this.loading = true

      try {
        const res = await useDataStore().publishResponse(this.review._id as string, this.responseDraft)
        this.$emit('update:review', res)
        this.isDirty = false
        this.dataStore.addToastMessage({
          severity: "positive",
          caption: this.$t("interactions.scheduled"),
          message: this.$t("app.success"),
        })
      } catch (e) {
        console.error(e)
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
      } finally {
        this.loading = false
      }
    },
    onSelectEmoji(insertValue: EmojiExt) {

      if (!insertValue) return

      const textarea = (this.$refs.input as any).getNativeElement() as HTMLTextAreaElement
      let originalPos = textarea.selectionStart
      let pos = 0

      if (originalPos !== undefined) {
        // as we use an array to prevent cutting the 2-byte emojis in the middle we need to re-calculate the position for the array without counting the chars
        pos = Array.from(textarea.value.slice(0, originalPos)).length
      }

      // @see https://dev.to/mistval/beware-of-emoji-in-js-144o
      const textArray = Array.from(textarea.value)

      const before = textArray.slice(0, pos)
      const after = textArray.slice(pos)

      this.responseDraft = `${before.join('')}${insertValue.i}${after.join('')}`

      this.$nextTick().then(() => {
        // as we only insert 2-byte chars increase by 2
        textarea.selectionStart = originalPos + 2
      })
    }
  },
  computed: {
    showForm() {
      
      switch (this.review.platformName) {
        case 'google':
          return !this.review.responses.some((r) => /published/.test(r.state))
        case 'booking-readonly':
          return this.review.message !== null  
        default:
          return true  

      }

    },
    reviewIsReadonly() {
      return this.review?.platformName.includes('-readonly')
    },
    reviewIsLocked() {
      return hasJob(this.review, "deleteResponse") || hasJob(this.review, "draftResponsePublish")
    },
    reviewSubTitle(): string {

      const response: Response | undefined = this.review?.responses[this.review.responses.length - 1]

      switch (response?.state) {
        case 'hyoban-manual-published':  
        case 'hyoban-auto-published':
        case 'external-published':
        case 'published':
          return `${this.$t('interactions.published')} ${this.$d(new Date(response.datePublished), 'short')}`

      }

      return this.review?.responses[this.review.responses.length - 1].state
    },
    reviewTenantName(): string {
      let name = '-';
      const tenant = useAuthStore().getTenants().filter(t => t.id === this.review?.tenantId)[0]
      if (tenant) {
        name = tenant.name
      }
      return name
    },
    hint() {
      return this.review.responseDraft ? `${this.$t('interactions.draft')} ${this.review.responseDraft.lastChangeByEmail} ${this.$t('app.at_time')} ${this.$d(new Date(this.review.responseDraft.lastChange), 'long')}` : ''
    }
  },
  watch: {
    review(newVal: Review) {
      this.translationStore.reset('all')
      this.responseDraft = newVal.responseDraft?.text || ''
    },
    '$i18n.locale'() {
      this.translationStore.reset('all')
    }
  },
  mounted() {
    this.responseDraft = this.review.responseDraft?.text || ''
  }
})

</script>
