<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $t("app.settings") }}</h1>
    <div v-if="authStore.getTenants().length > 1" class="row q-mb-xl">
      <div class="col-12 text-center">
        {{ $t('settings.tenant') }} <tenant-filter @change="fetch($event)" />{{ $t('settings.tenant2') }}.
      </div>
    </div>
    <div class="row q-col-gutter-md" v-if="settings">
      <!-- ############## BEGIN CORE ############## -->
      <div class="col-12">
        <h2 class="text-center">{{ $t('settings.core') }}</h2>
        <p class="text-center q-mb-lg q-text-info">{{ $t('settings.coreDescription') }}</p>
      </div>
      <div class="col-12 col-md-6">
        <q-input v-model="settings.name" :label="$t('settings.fields.brand')" color="hy-primary" filled readonly />
      </div>
      <div class="col-12 col-md-6">
        <q-input filled v-model="settings.websiteUrl" :label="$t('settings.fields.website')" :disable="loading"
          color="hy-primary">
          <template v-slot:after>
            <q-btn flat round color="hy-primary" icon="hdr_auto" @click="generateSettings()"
              :disable="!settings.websiteUrl || loading">
              <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.parse') }}</q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
      <div class="col-12">
        <q-select filled v-model="settings.sectors" use-input use-chips multiple hide-dropdown-icon color="hy-primary"
          input-debounce="0" @new-value="(val: string, done: any) => done(val, 'add-unique')"
          :label="$t('settings.fields.sectors.label')" :hint="$t('settings.fields.sectors.hint')"
          :disable="loading" />
      </div>
      <div class="col-12">
        <q-select filled v-model="settings.tonality" use-input use-chips multiple hide-dropdown-icon color="hy-primary"
          input-debounce="0" @new-value="(val: string, done: any) => done(val, 'add-unique')"
          :label="$t('settings.fields.tonality.label')" :hint="$t('settings.fields.tonality.hint')"
          :disable="loading" />
      </div>
      <!-- ############## END CORE ############## -->
       <!-- ############## BEGIN LANGUAGES ############## -->
      <div class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="text-center">{{ $t('settings.languages.heading') }}</h2>
        <p class="text-center q-mb-lg q-text-info" v-html="$t('settings.languages.description')"></p>
        <div class="row q-col-gutter-md q-mb-md">
          <div class="col-12 col-md-6">
            <q-select filled v-model="languageSettingsAuto" :options="languageOptions" color="hy-primary" :label="$t('settings.languages.auto')" class="hy-auto-languages"
                  :disable="loading" @filter="languageFilter" use-input input-debounce="0" fill-input multiple emit-value map-options >
                  <template v-slot:selected>
                     
                    <q-chip
                      v-for="iso in languageSettingsAuto"
                      removable
                      @remove="languageSettingsAuto.splice(settings.languageSettings.auto.indexOf(iso), 1)"
                      class="q-my-none q-ml-xs q-mr-none"
                    >
                      {{ $t(`settings.languages.list.${iso}`) }}
                    </q-chip>
                    <span v-if="!languageSettingsAuto.length">{{ $t(`settings.languages.all`) }}</span>
                </template>
                </q-select>
          </div>
          <div class="col-12 col-md-6">
            <q-select filled v-model="languageSettingsDefault" :options="languageOptions" color="hy-primary" :label="$t('settings.languages.default')"
                  :disable="loading" @filter="languageFilter" use-input input-debounce="0" hide-selected fill-input emit-value map-options>
                  
                </q-select>
          </div>
        </div>
        <p class="text-center">
          {{ $t('settings.languages.supported') }} <span v-for="(lang, index) in options.language" class="q-mr-xs">
            <i>{{ lang.label }}</i>{{ index < options.language.length - 2 ? ', ' : index < options.language.length - 1 ? ` ${$t('app.and')}` : '' }}
          </span>.
        </p>
          
      </div>  
        <!-- ############## END LANGUAGES ############## -->
      <!-- ############## BEGIN RESPONSES ############## -->
      <div class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="text-center">{{ $t('settings.responses') }}</h2>
        <p class="text-center q-mb-lg q-text-info">{{ $t('settings.responsesDescription') }}</p>
        <table style="width: 100%" class="hy-table gt-sm">
          <thead>
            <tr>
              <th style="width: 24%">{{ $t('settings.platform') }}</th>
              <th style="width: 24%">{{ $t('settings.sentiment') }}</th>
              <th style="width: 24%">
                {{ $t('settings.generation') }}
              </th>
              <th style="">
                {{ $t('settings.tonality') }}
              </th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in settings.responseSettings" >
              <td v-if="index > 0">
                <span class="hidden">{{ row.platform }}</span>
                <q-select filled v-model="settings.responseSettings[index].platform" dense :options="settings.platformsEnabled" color="hy-primary"
                  :disable="loading" />
              </td>
              <td v-if="index > 0">
                <q-select filled emit-value map-options v-model="settings.responseSettings[index].sentiment" dense
                  :options="options.sentiment" color="hy-primary" :disable="loading">
                  <template v-slot:option="{ itemProps, opt }">
                    <q-item v-bind="itemProps">
                      <q-item-section>
                        <q-item-label v-html="opt.label" :class="opt.isChild ? 'q-pl-md' : ''" />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </td>
              <td v-if="index > 0">
                <q-select filled emit-value map-options v-model="settings.responseSettings[index].generation" dense
                  :is-option-disabled="(opt: any) => opt.disabled"
                  :options="getResponseGenerationOptions(settings.responseSettings[index].platform as string)" color="hy-primary" :disable="loading" />
              </td>
              <td v-if="index > 0">
                <q-select filled emit-value map-options v-model="settings.responseSettings[index].tonality" dense
                  :options="options.tonality"
                  :disable="loading || settings.responseSettings[index].generation === 'manual'" color="hy-primary" />
              </td>
              <td v-if="index > 0" class="q-pl-sm">
                <q-btn flat round icon="delete" @click="settings.responseSettings.splice(index, 1)" color="hy-primary"
                  :disable="loading">
                  <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.removeReplySetting') }}</q-tooltip>
                </q-btn>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                <span v-if="settings.responseSettings.length === 1">{{ $t('settings.defaultResponse.all') }}</span>
                <span v-else>{{ $t('settings.defaultResponse.remaining') }}</span>
              </td>
              <td>
                <q-select filled emit-value map-options v-model="settings.responseSettings[0].generation" dense
                  :options="getResponseGenerationOptions('remaining')" color="hy-primary" :disable="loading" />
              </td>
              <td>
                <q-select filled emit-value map-options v-model="settings.responseSettings[0].tonality" dense
                  :options="options.tonality" color="hy-primary" :disable="loading" />
              </td>
              <td class="q-pl-sm">
                <q-btn round flat icon="add_circle" @click="addResponseSetting()" :disable="loading" color="hy-primary">
                  <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.addReplySetting') }}</q-tooltip>
                </q-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="hy-table lt-md">
          <p class="text-center">{{ $t('settings.mobile.message') }}</p>
          <ul>
            <template v-for="(res, index) in settings.responseSettings">
              <li v-if="index > 0">
                <i>{{ options.sentiment.filter(s => s.value === res.sentiment)[0]?.label  }}</i> 
                {{ $t('settings.mobile.on') }} 
                <i>{{ res.platform }}</i>: 
                <i>{{ options.generation.filter(s => s.value === res.generation)[0]?.label  }}</i> 
                {{ $t('settings.mobile.with') }}
                <i>{{ options.tonality.filter(s => s.value === res.tonality)[0]?.label  }}</i> 
              </li>
            </template>
            <li>
              <span v-if="settings.responseSettings.length === 1">{{ $t('settings.defaultResponse.all') }}: </span>
              <span v-else>{{ $t('settings.defaultResponse.remaining') }}: </span>
              <i>{{ options.generation.filter(s => s.value === settings?.responseSettings[0].generation)[0].label  }}</i> 
              {{ $t('settings.mobile.with') }}
              <i>{{ options.tonality.filter(s => s.value === settings?.responseSettings[0].tonality)[0].label  }}</i> 
            </li>
          </ul>
        </div>
      </div>
      <!-- ############## END RESPONSES ############## -->  
      
      <!-- ############## BEGIN SIGNATURES ############## -->
      <div class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="text-center">{{ $tc('settings.signatures', 2) }}</h2>
        <p class="text-center q-mb-lg q-text-info">{{ $t('settings.signaturesDescription') }}</p>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <q-input filled v-model="settings.signatures[0].content" type="textarea"
          color="hy-primary" :label="$tc('settings.signatures', 1)" class="q-mb-md" :disable="loading" />
        <q-select filled emit-value map-options v-model="settings.signatures[0].tonality" :options="options.tonality"
          color="hy-primary" :label="$t('settings.tonality')" :disable="loading" />
        <div class="text-center">
          <q-btn @click="addSignature()" v-if="settings.signatures.length === 1" round flat icon="add_circle" color="hy-primary"
            class="q-mt-md" :disable="loading">
            <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.addSignature') }}</q-tooltip>
          </q-btn>
          <q-btn @click="removeSignature(0)" v-if="settings.signatures.length > 1" round flat icon="delete"
            color="hy-primary" class="q-mt-md" :disable="loading">
            <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.removeSignature') }}</q-tooltip>
          </q-btn>
        </div>
      </div>
      <div v-for="index in settings.signatures.length - 1" class="col-12 col-sm-6 col-md-4">
        <q-input filled v-model="settings.signatures[index].content" type="textarea"
          color="hy-primary" :label="$tc('settings.signatures', 1)" class="q-mb-md" :disable="loading" />
        <q-select filled emit-value map-options v-model="settings.signatures[index].tonality" :options="options.tonality"
          color="hy-primary" :label="$t('settings.tonality')" :disable="loading" />
        <div class="text-center">
          <q-btn @click="removeSignature(index)" v-if="settings.signatures.length > 1" round flat icon="delete"
            color="hy-primary" class="q-mt-md " :disable="loading">
            <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.removeSignature') }}</q-tooltip>
          </q-btn>
          <q-btn @click="addSignature()" v-if="settings.signatures.length - 1 === index" round flat icon="add_circle"
            color="hy-primary" class="q-mt-md" :disable="loading" >
            <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.addSignature') }}</q-tooltip>
          </q-btn>
        </div>
      </div>
      <!-- ############## END SIGNATURES ############## -->
      <!-- ############## BEGIN CUSTOM RULES ############## -->
      <div class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="text-center">{{ $t('settings.customRules') }}</h2>
        <p class="text-center q-mb-lg q-text-info">{{ $t('settings.customRulesDescription') }}</p>
        <table style="width: 100%">
          <tbody>
            <tr v-for="index in settings.customRules.length">
              <q-input filled v-model="settings.customRules[index -1]" type="textarea" rows="3"
                color="hy-primary" :label="`${$t('settings.rule')} #${index}`" class="q-mb-md" :disable="loading" >
                <template v-slot:after>
                  <q-btn @click="settings.customRules.splice(index - 1, 1)" round flat icon="delete"
                        color="hy-primary" :disable="loading" >
                    <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.removeRule') }}</q-tooltip>
                  </q-btn>
                </template>
              </q-input>
            </tr>
          </tbody>
        </table>
        <div class="text-center">
          <q-btn flat round icon="add_circle" color="hy-primary" class="q-mt-md" @click="settings.customRules.push('')" :disable="loading">
            <q-tooltip :delay="500" anchor="top middle" self="bottom middle">{{ $t('settings.tooltip.addRule') }}</q-tooltip>
            </q-btn>
        </div>
      </div>
      <!-- ############## END CUSTOM RULES ############## -->
      <div class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="q-mt-lg text-center">{{ $t('settings.platforms') }}</h2>
        <p v-if="settings && tenantPlatforms.length" class="text-center">
          {{ $t('settings.platformsTracked.one') }}
          <span class="text-hy-primary" v-for="(platform, index) in tenantPlatforms">
            {{ platform.name.replaceAll('-readonly', '') }}<span class="text-hy-default"
              v-if="index < tenantPlatforms.length - 1 && index !== tenantPlatforms.length - 2">, </span>
            <span class="text-hy-default" v-if="index === tenantPlatforms.length - 2">&nbsp;{{ $t('app.and')
            }}&nbsp;</span></span>.
          {{ $t('settings.platformsTracked.two') }}
        </p>
        <p v-else-if="settings" class="text-center">
          {{ $t('settings.noPlatform') }}
        </p>
      </div> 
      <!-- ############## BEGIN REPORT CATEGORIES ############## -->
      <div v-if="settings.insights?.enabled" class="col-12">
        <q-separator class="q-ma-xl"></q-separator>
        <h2 class="q-mt-lg text-center">{{ $t('settings.categories') }}</h2>
        <p class="text-center">
          {{ $t('settings.categoriesDescription') }}
        </p>
      </div>  
      <div v-if="settings.insights?.enabled" class="col-12">
        <q-select filled v-model="settings.insights.categories" use-input use-chips multiple hide-dropdown-icon color="hy-primary"
          input-debounce="0" @new-value="(val: string, done: any) => done(val, 'add-unique')"
          :label="$t('settings.fields.categories.label')" :hint="$t('settings.fields.categories.hint')"
          :disable="loading" />
      </div>
      <!-- ############## END REPORT CATEGORIES ############## -->
    </div>  
    <q-page-sticky position="bottom-right" :offset="[36, 36]">
      <q-btn fab :icon="loading ? undefined : 'save'" color="hy-primary" @click="saveSettings()" :disable="loading || !isValid" >
        <q-inner-loading color="white"  :showing="loading" size="1.75rem" />
        <q-tooltip anchor="top middle" self="bottom middle"><span v-html="saveTooltip"></span></q-tooltip>
      </q-btn>
    </q-page-sticky>
</section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import TenantFilter from '@/components/Filter/TenantForm.vue';
import { useDataStore } from '@/stores/data';
import { useAuthStore } from '@/stores/auth';
import { FilterOption, Tenant } from '@/types';

export default defineComponent({
  components: {
    TenantFilter
  },
  data() {
    return {
      dataStore: useDataStore(),
      authStore: useAuthStore(),
      tenantId: useAuthStore().getTenants()[0].id,
      settings: null as Tenant | null,
      loading: true,
      languageOptions: [] as FilterOption[]
    }
  },
  methods: {
    async generateSettings() {

      if (!this.settings || !this.settings.websiteUrl) {
        return
      }
      
      this.loading = true

      try {

        const res = await this.dataStore.generateSettings(this.tenantId, this.settings.websiteUrl)
        
        this.settings.sectors = res.sectors
        this.settings.tonality = res.tonality
        this.settings.customRules.push(res.reviewToneInstructions)

        this.dataStore.addToastMessage({
          severity: "positive",
          caption: this.$t("settings.successGenerate"),
          message: this.$t("app.success"),
        })

      } catch (e) {
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("settings.errorGenerate"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }

    },
    async saveSettings() {
      if (!this.settings) {
        return
      }
      
      this.loading = true

      try {
        this.settings = await this.dataStore.saveSettings(this.settings)
        this.dataStore.addToastMessage({
          severity: "positive",
          caption: this.$t("settings.successSave"),
          message: this.$t("app.success"),
        })

        //@todo re-generate tokens for new tenant name info
      } catch (e) {
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("settings.errorSave"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }
      

    },
    addResponseSetting() {
      this.settings?.responseSettings.push({
        platform: this.settings.platformsEnabled[0],
        sentiment: '*',
        generation: 'manual',
        tonality: 'auto'
      })
    },
    addSignature() {
      this.settings?.signatures.push({
        content: '',
        tonality: 'auto'
      })
    },
    removeSignature(index: number) {
      this.settings?.signatures.splice(index, 1)
    },
    async fetch(tenantId: string) {
      this.tenantId = tenantId
      this.loading = true
      try {
        this.settings = await this.dataStore.fetchSettings(tenantId);

        //manually add default response setting if not given
        if (!this.settings?.responseSettings.length) {
          this.settings?.responseSettings.push({
            sentiment: '*',
            generation: 'manual',
            tonality: 'auto'
          })
        }


      } catch (e) {
        this.settings = null

        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }
    },
    getResponseGenerationOptions(platform: string) {
      return this.options.generation.filter(g => !(platform !== 'google' && platform !== 'facebook' && g.value === 'auto'))
    },
    languageFilter(val: string, update: any) {
      
      if (!val) {
        update(() => {
          this.languageOptions = this.options.language
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.languageOptions = this.options.language.filter(o => o.label.toLowerCase().indexOf(needle) > -1)
      })
    }
  },
  computed: {
    // getter/setter for backwards compatibility
    languageSettingsAuto: {
      get() {
        return this.settings?.languageSettings?.auto || []
      },
      set(val: string[]) {

        if (!this.settings){
          console.warn('settings not set')
          return
        }

        if (!this.settings.languageSettings) {
          this.settings.languageSettings = {
            auto: [],
            default: 'de'
          }
        }

        this.settings.languageSettings.auto = val
      }
    },
    // getter/setter for backwards compatibility
    languageSettingsDefault: {
      get() {
        return this.settings?.languageSettings?.default || 'en'
      },
      set(val: string) {

        if (!this.settings){
          console.warn('settings not set')
          return
        }

        if (!this.settings.languageSettings) {
          this.settings.languageSettings = {
            auto: [],
            default: 'de'
          }
        }

        this.settings.languageSettings.default = val
      }
    },
    options() {

      const langOpts: FilterOption[] = [];

      const langCodes = ["de","en","fr","es","it","nl","ru","pt","el","tr","zh","ja"]

      langCodes.forEach(code => {
        langOpts.push({ label: this.$t(`settings.languages.list.${code}`), value: code })
      })

      langOpts.sort((a, b) => a.label.localeCompare(b.label))

      return {
        language: langOpts,
        sentiment: [
          { label: this.$t('app.all'), value: '*' },
          { label: this.$t('dashboard.sentiment.allPositive'), value: 'positive_all' },
          { label: this.$t('dashboard.sentiment.veryPositive'), value: 'positive_high', isChild: true },
          { label: this.$t('dashboard.sentiment.positive'), value: 'positive_mid', isChild: true },
          { label: this.$t('dashboard.sentiment.slightlyPositive'), value: 'positive_low', isChild: true },
          { label: this.$t('dashboard.sentiment.neutral'), value: 'neutral' },
          { label: this.$t('dashboard.sentiment.allNegative'), value: 'negative_all' },
          { label: this.$t('dashboard.sentiment.slightlyNegative'), value: 'negative_low', isChild: true },
          { label: this.$t('dashboard.sentiment.negative'), value: 'negative_mid', isChild: true },
          { label: this.$t('dashboard.sentiment.veryNegative'), value: 'negative_high', isChild: true },
        ],
        tonality: [
          { label: this.$t('settings.tonalityOptions.auto'), value: 'auto' },
          { label: this.$t('settings.tonalityOptions.serious'), value: 'serious' },
          { label: this.$t('settings.tonalityOptions.neutral'), value: 'neutral' },
          { label: this.$t('settings.tonalityOptions.loose'), value: 'loose' },
          { label: this.$t('settings.tonalityOptions.loose_no_emotes'), value: 'loose_no_emotes' },
        ],
        generation: [
          { label: this.$t('settings.generationOptions.manual'), value: 'manual' },
          { label: this.$t('settings.generationOptions.auto'), value: 'auto', disabled: true},
          { label: this.$t('settings.generationOptions.semiAuto'), value: 'semi-auto' },
        ]
      }
    },
    tenantPlatforms() {
      return this.authStore.getPlatforms(this.tenantId)
    },
    isValid() {
      return this.settings &&
        this.settings.name &&
        this.settings.responseLanguage &&
        this.settings.sectors.length &&
        this.settings.tonality.length &&
        this.settings.signatures.length &&
        this.settings.signatures[0].content
    },
    saveTooltip() {

      if (this.isValid) {
        return this.$t('settings.tooltip.save')
      }
      let msg = this.$t('settings.validation.message') + '<ul>'

      if (!this.settings?.name) {
        msg += '<li>' + this.$t('settings.validation.brand') + '</li>'
      }

      if (!this.settings?.sectors.length) {
        msg += '<li>' + this.$t('settings.validation.sector') + '</li>'
      }
      if (!this.settings?.tonality.length) {
        msg += '<li>' + this.$t('settings.validation.tonality') + '</li>'
      }
      if (!this.settings?.signatures.length || !this.settings?.signatures[0].content) {
        msg += '<li>' + this.$t('settings.validation.signature') + '</li>'
      }

      return msg
      
    }
    
  },
  mounted() {
    if(this.authStore.getTenants().length === 1) {
      this.fetch(this.tenantId)
    }
    this.languageOptions = this.options.language
  }

})
</script>
<style lang="sass">
.hy-auto-languages .q-field__label 
  transform: translateY(-40%) scale(0.75)
</style>