<template>
  <div style="height: 200px; position: relative;">
    <div ref="chart" style="height: 200px;"></div>
    <q-inner-loading :showing="loading" />
    <div v-if="!loading && (!data || !data.length)" class="justify-center items-center chart-empty">{{ $t('app.noData') }}</div>
    <hy-review-list :item="showReviews" type="date" @hide="showReviews = null" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";
import { Insights, StatementsItem } from "@/types";
import HySentimentMixin from '@/mixins/Sentiment.vue';
import HyReviewList from '@/components/Report/ReviewList.vue';

export default defineComponent({
  mixins: [HySentimentMixin],
  components: {
    HyReviewList
  },
  props: {
    data: {
      type: [Object, null] as PropType<Insights['development']['sentiment'] | null>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  data() {
    return {
      chart: null as null | Highcharts.Chart,
      showReviews: null as StatementsItem | null
    };
  },
  methods: {
    render() {

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      if (!this.data || !this.data.length) {
        return;
      }

      const categories = this.data.map(d => this.$d(new Date(d.date), 'tiny'));

      let me = this

      this.chart = Highcharts.chart({
        chart: {
          renderTo: this.$refs.chart as HTMLElement,
          styledMode: true
        },
        accessibility: { enabled: false },
        legend: { enabled: false },
        credits: { enabled: false },
        title: { text: undefined },
        xAxis: {
          tickInterval: this.data.length > 10 ? 6 : 2,
          labels: {
            rotation: 0,
            formatter: function (item: any) {
              return categories[item.pos];
            }
          }
        },
        yAxis: [{
          tickAmount: 3,
          alternateGridColor: true,
          min: -1,
          max: 1,
          title: {
            text: undefined
          },
          labels: {
            useHTML: true,
            formatter: function () {
              // @ts-ignore
              if (this.value === 1) {
                return '<i style="font-size: 20px" class="text-hy-default q-icon notranslate material-icons" aria-hidden="true" role="presentation">thumb_up</i>'
              }
              // @ts-ignore
              if (this.value === -1) {
                return '<i style="font-size: 20px" class="q-icon notranslate material-icons" aria-hidden="true" role="presentation">thumb_down</i>'
              }

              return '';
            }
          }
        }
        ] as unknown as Highcharts.YAxisOptions[],
        series: [
          {
            name: 'average',
            type: 'spline',
            className: 'hy-series-primary hy-series-drilldown',
            data: this.data.map(d => { return {y: d.sentiment}}),
            marker: {enabled: false, symbol:"circle"},
          }
        ] as Highcharts.SeriesOptionsType[],
        tooltip: {
          shared: true,
          useHTML: true,
          outside: false,
          //@ts-ignore
          // positioner: function (labelWidth, labelHeight, point) {
          //   return { x: point.plotX - 165, y: point.plotY - 250 };
          // },
          formatter() {
            let html = `<div class="hy-tooltip"><table>`
            // @ts-ignore
            html += `<tr><th colspan="2" class="text-center">${categories[this.x]}</th></tr>`
            // @ts-ignore
            html += `<tr><td>${me.$t('reports.reviews')}:</td><td class="text-right">${me.data[this.x].reviewCount}</td></tr>`
            // @ts-ignore
            html += `<tr><td>${me.$t('reports.sentiment')}:</td><td class="text-right">${me.resolveSentiment(this.y)}</td></tr>`
            html += `<tr><td colspan="2" style="white-space: normal" class="text-hy-secondary text-center text-wrap">${me.$t('reports.drilldownHint')}</td></tr>`
            html += `</table></div>`
            return html
          }
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            stacking: 'normal',
            point: {
              events: {
                click: function () {
                  me.showReviews = {
                    // @ts-ignore
                    title: me.$d(new Date(me.data[this.x].date), 'short'),
                    caption: [],
                    // @ts-ignore
                    reviewIds: me.data[this.x].reviewIds,
                    // @ts-ignore
                    count: me.data[this.x].reviewCount,
                  }

                  me.$emit(
                    'drilldown', 
                    //@ts-ignore
                    me.data[this.x].reviewIds
                  )
                }
              }
            }
          }
        }

      })
    }
  },
  watch: {
    data() {
      this.render();
    },
    '$i18n.locale': function () {
      this.render();
    },
    '$q.dark.isActive': function () {
      this.render();
    }
  },
  mounted() {
    this.render();
  }
});
</script>
<style lang="sass" scoped>
.chart-empty
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: var(--q-color-grey-5)
  text-align: center
  z-index: 1
  pointer-events: none
  width: 100%
 </style>