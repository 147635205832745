import { defineStore } from 'pinia'
import type { AuthState, Platform, Scopes, UserTenat } from '@/types'
import axios from 'axios'


const parseJwt = (token: string): {tenants: UserTenat[], scopes: Scopes, email: string, exp: number} => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const useAuthStore = defineStore('auth', {
  state(): AuthState {
    return {
      customIdToken: null,
      refreshToken: null,
      tenenats: [],
      scopes: null,
      email: null,
      loading: false
    }
  },
  actions: {
    init() {
      if (localStorage.getItem('hyoban.customIdToken') && localStorage.getItem('hyoban.refreshToken')) {
        const storedToken = parseJwt(localStorage.getItem('hyoban.customIdToken') as string)
        this.customIdToken = localStorage.getItem('hyoban.customIdToken')
        this.refreshToken = localStorage.getItem('hyoban.refreshToken')
        this.tenenats = storedToken.tenants
        this.scopes = storedToken.scopes
        this.email = storedToken.email
      }
    },
    
    async login(email: string, password: string) {

      try {

        this.loading = true
        const res = await axios.post('/auth/login', {
          email,
          password
        });

        localStorage.setItem('hyoban.customIdToken', res.data.customIdToken as string)
        localStorage.setItem('hyoban.refreshToken', res.data.customRefreshToken as string)
        this.init()

      } catch (error) {
        throw new Error('Login failed')
      } finally {
        this.loading = false
      }
    },
    logout() {
      this.customIdToken = null
      this.refreshToken = null
      this.tenenats = []
      this.scopes = null
      this.email = null
      localStorage.removeItem('hyoban.customIdToken')
      localStorage.removeItem('hyoban.refreshToken')
      // remove filters and so on
      Object.keys(sessionStorage).forEach(function(k) {
        sessionStorage.removeItem(k);
      });
    },
    async refreshIdToken(): Promise<void> {
      try {
        const res = await axios.post('/auth/refresh', {
          refreshToken: this.refreshToken
        })
        localStorage.setItem('hyoban.customIdToken', res.data.customIdToken as string)
        localStorage.setItem('hyoban.refreshToken', res.data.customRefreshToken as string)
        this.init()
        
      } catch (error) {
        console.error('Token refresh failed')
      }
      
    },
    getScopes(): Scopes {

      return this.scopes || {
        dashboard: 'disabled',
        interactions: 'disabled',
        adHoc: 'disabled',
        insights: 'disabled',
        settings: 'disabled',
        competition: 'disabled'
      }

    },
    getTenants(): UserTenat[] {
      return this.tenenats
    },
    getPlatforms(tenantId?: string): Platform[] {
      const allPlatforms: Platform[] = []

      this.getTenants().forEach(tenant => {
        if(!tenantId || tenant.id === tenantId) {
          tenant.platformsEnabled.forEach(platform => {
            if (!allPlatforms.find(item => item.id === platform)) {
              allPlatforms.push({
                id: platform,
                name: platform.charAt(0).toUpperCase() + platform.slice(1)
              })
            }
          })
        }
      })

      allPlatforms.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      return allPlatforms

    }
  },
  getters: {
    isLoading() :boolean {
      return this.loading
    },
    isAuthenticated(): boolean {
      return this.customIdToken !== null
    },
    isAboutToExpire(): boolean {
      
      if (!this.customIdToken) {
        return false
      }
      const token = parseJwt(this.customIdToken)
      
      // expire 5 minutes before the actual expiry
      const exp = new Date(token.exp * 1000 - (60000 * 5 ))
      
      const now = new Date()
      
      return exp < now
    }
  }
})
