<template>
  <div class="text-center">
    <span v-if="compare">{{ $t('reports.filter.startCompare') }}&nbsp;</span>
    <span v-else>{{ $t('reports.filter.start') }}&nbsp;</span>
    <span v-if="isMultiTenant()"><filter-field type="tenant" v-model="filter.tenant"  @update:model-value="emitChange(true, true)" :allow-all-tenants="false"/></span>
    <span v-else>{{ selectedTenantName }}</span>
    <span v-if="compare">&nbsp;{{ $t('reports.filter.compareTo') }}&nbsp;</span>
    <div v-if="compare" class="filter-field text-hy-primary">
      {{ selectedCompetitorName }}<q-menu anchor="center middle" self="center middle" transition-show="scale" transition-hide="scale">
        <q-list style="min-width: 100px">
          <q-item clickable v-close-popup v-for="option in reportStore.availableCompetitors" @click="filter.competitor = option.value; emitChange()">
            <q-item-section>{{ option.label }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </div>
    <span >&nbsp;{{ $t('reports.filter.range') }}&nbsp;</span>
    <filter-field type="range" v-model="filter.range"  @update:model-value="emitChange()"/>
    <span v-if="!compare">&nbsp;{{ $t('reports.filter.end') }}</span>.
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FilterField from './Field.vue';
import { useAuthStore } from '@/stores/auth';
import { ReportFilter } from '@/types';
import { useReportStore } from '@/stores/report';

export default defineComponent({
  components: {
    FilterField
  },
  props: {
    compare: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['change'],
  data() {
    return {
      filter: {
        range: this.getInitialValue('range', this.$route.params.range),
        tenant: this.getInitialValue('tenant'),
        competitor: '' as string,
      } as ReportFilter,
      reportStore: useReportStore()
    }
  },
  methods: {
    emitChange(addToStore = true, defer = false) {

      if (addToStore) {
        sessionStorage.setItem('hyoban.range', JSON.stringify(this.filter.range))
        sessionStorage.setItem('hyoban.tenant', this.filter.tenant)
        
      }

      if (this.compare && defer) {
        return;
      }

      this.$emit('change', {
        range: this.filter.range,
        tenant: this.filter.tenant,
        competitor: this.filter.competitor
      })
    },
    isMultiTenant() {
      return useAuthStore().getTenants().length > 1
    },
    getInitialValue(filter: string) {

      // get value from session storage
      const value = sessionStorage.getItem(`hyoban.${filter}`)

      if (filter === 'tenant') {
        if (value === '*') {
          return useAuthStore().getTenants()[0].id  
        }
        return value || useAuthStore().getTenants()[0].id
      }
      if (value) {
        return filter === 'range' ? JSON.parse(value) : value
      }

      if (filter === 'range') {
        return { type: 'predefined', value: '7_days' as string }
      }

    }
  },
  computed: {
    selectedTenantName() {
      return useAuthStore().getTenants().find(t => t.id === this.filter.tenant)?.name
    },
    selectedCompetitorName() {
      return this.reportStore.availableCompetitors.find(c => c.value === this.filter.competitor)?.label
    },
  },
  mounted() {
    
    if (this.compare) {
      this.reportStore.setCompetitorOptions(this.filter.tenant).then(() => {
        this.filter.competitor = this.reportStore.availableCompetitors[0].value
        this.emitChange(false)
      })
    } else {
      this.emitChange(false)
    }
  },
  watch: {
    'filter.tenant'() {
      if (this.compare) {
        this.reportStore.setCompetitorOptions(this.filter.tenant)
      }
    },
  }
})
</script>
<style lang="sass" scoped>
@import '@/styles/variables.sass'
.filter-field
  display: inline
  cursor: pointer
  
.body--light
  .filter-field
    text-decoration: underline dotted $hy-text-default--light  
.body--dark
  .filter-field
    text-decoration: underline dotted $hy-text-default--dark

</style>
