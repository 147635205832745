import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import type { Scope } from '@/types'

import Dashboard from '@/views/Dashboard.vue'
import Interactions from '@/views/Interactions.vue'
import Settings from '@/views/Settings.vue'
import Insights from '@/views/Insights.vue'
import Competition from '@/views/Competition.vue'
import AdHoc from '@/views/AdHoc.vue'
import Login from '@/views/Login.vue'
import AccessNotBooked from '@/views/AccessNotBooked.vue'
import AccessDenied from '@/views/AccessDenied.vue'
import Styles from '@/views/Styles.vue'


const routes = [
  { path: '/', component: Dashboard, name: 'dashboard' as Scope},
  { path: '/interactions/:platform?/:range?', component: Interactions, name: 'interactions' as Scope },
  { path: '/insights', component: Insights, name: 'insights' as Scope},
  { path: '/competition', component: Competition, name: 'competition' as Scope},
  { path: '/settings', component: Settings, name: 'settings' as Scope},
  { path: '/ad-hoc', component: AdHoc, name: 'adHoc' as Scope},
  { path: '/login', component: Login },
  { path: '/not-booked/:scope', component: AccessNotBooked },
  { path: '/403', component: AccessDenied },
  //@todo dev only 
  { path: '/styles', component: Styles },
]

const getRouter = () => {

  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  })

  //@ts-ignore
  router.beforeEach(async (to, from, next) => {
    
    if (!useAuthStore().isAuthenticated && to.path !== '/login') {
      next('/login');
      return;
    }

    if(useAuthStore().isAuthenticated && to.path === '/login') {
      next('/');
      return;
    }


    if (to.name) {
      const access = useAuthStore().getScopes()[to.name as Scope];
      if(access === 'not-booked') {
        next(`/not-booked/${to.name as string}`);
        return;
      }
  
      if(access === 'disabled') {
        next(`/403/`);
        return;
      }
    }

    next();

  });
  return router
}

export { getRouter }