<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $t("app.competition") }}</h1>
    <div class="row q-mb-xl">
      <div class="col-12">
        <fn-filter @change="fetch($event)" compare />
      </div>
    </div>
    <div class="row q-mb-xl" v-if="!loading && !data">
      <div class="col-12 text-center">
        {{ $t('app.noData') }}
      </div>
    </div>
    <pre>{{ filter }}</pre>
    <pre>{{ data }}</pre>
  </section>
</template>

<script lang="ts">
import type { Filter } from '@/types';
import { defineComponent } from 'vue';
import FnFilter from '@/components/Filter/ReportForm.vue';
import { useDataStore } from '@/stores/data';

export default defineComponent({
  components: {
    FnFilter
  },
  data() {
    return {
      dataStore: useDataStore(),
      filter: null as null | Filter,
      loading: true,
      data: {}
    }
  },
  methods: {
    async fetch(filter: Filter) {
      console.log('fetch')
      this.filter = filter
      this.loading = true

    }
  },
  
  watch: {
    '$i18n.locale'() {
      this.fetch(this.filter as Filter)
    }
  }

})
</script>