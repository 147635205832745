<template>
  <div style="height: 75px; position: relative;">
    <div ref="chart" style="height: 75px;"></div>
    <q-inner-loading :showing="loading" />
    <div v-if="!loading && (!data || !data.length)" class="justify-center items-center chart-empty">{{ $t('app.noData') }}</div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";
import { InsightsStatementsDevelopment } from "@/types";
import HySentimentMixin from '@/mixins/Sentiment.vue';

export default defineComponent({
  mixins: [HySentimentMixin],
  props: {
    data: {
      type: [Object, null] as PropType<InsightsStatementsDevelopment['development'] | null>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    slope: {
      type: Object as PropType<{slope: number | null, percentageChange: number | null}>,
      default: 0
    }
  },
  data() {
    return {
      chart: null as null | Highcharts.Chart,
    };
  },
  methods: {
    render() {

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      if (!this.data || !this.data.length) {
        return;
      }

      const categories = this.data.map(d => this.$d(new Date(d.date), 'tiny'));

      let me = this

      this.chart = Highcharts.chart({
        chart: {
          renderTo: this.$refs.chart as HTMLElement,
          styledMode: true
        },
        accessibility: { enabled: false },
        legend: { enabled: false },
        credits: { enabled: false },
        title: { text: undefined },
        xAxis: {
          labels: {
                enabled: false
            },
            title: {
                text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
        },
        yAxis: [{
          endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            tickPositions: [0]
        },{
          opposite: true,
          title: {
              text: null
          }
        }
        ] as unknown as Highcharts.YAxisOptions[],
        series: [
          {
            name: 'average',
            type: 'spline',
            className: 'hy-series-primary sparkline',
            data: this.data.map(d => { return {y: d.count}}),
            marker: {enabled: false, symbol:"circle"},
          },
          // {
          //   name: 'trend',
          //   type: 'spline',
          //   className: 'hy-series-secondary sparkline',
          //   data: categories.map((x, index) => { return {y: index * (this.slope.slope || 0)}}),
          //   marker: {enabled: false, symbol:"circle"},
          //   yAxis: 1
          // }
        ] as Highcharts.SeriesOptionsType[],
        tooltip: {
          hideDelay: 0,
          outside: true,
          shared: true,
          useHTML: true,
          formatter() {
            let html = `<div class="hy-tooltip">`
            // @ts-ignore
            html += `${me.$tc('reports.statementsInCategory', me.data[this.x].count)} ${categories[this.x]}`
            html += `</div>`
            return html
          }
        }

      })
    }
  },
  watch: {
    data() {
      this.render();
    },
    '$i18n.locale': function () {
      this.render();
    },
    '$q.dark.isActive': function () {
      this.render();
    }
  },
  mounted() {
    this.render();
  }
});
</script>
<style lang="sass" scoped>
.chart-empty
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: var(--q-color-grey-5)
  text-align: center
  z-index: 1
  pointer-events: none
  width: 100%
 </style>