<template>
  <div class="relative-position" style="min-height: 100px">
    <q-inner-loading :showing="loading" style="z-index: 9" />
    <p v-if="!items.length && !loading" class="text-hy-default text-center q-mt-md">
      {{ $t('app.noData') }}
    </p>
    <q-list separator>
      <q-item v-for="(item, index) in items" :key="index" clickable @click="toggleItem(index, item._id?.categoryHash as string)">
        <q-item-section side class="item-count text-hy-default">
          <div class="nowrap">
            
            {{ item.count }}&nbsp;
             <q-icon :name="type === 'positive' ? 'thumb_up' : 'thumb_down'" />&nbsp;
             <q-icon :name="processedTrends[(item._id?.categoryHash as string)] ? processedTrends[(item._id?.categoryHash as string)].icon : 'remove'" />&nbsp;
            <q-tooltip anchor="top middle" self="bottom middle" :delay="300">
              {{ $tc(type === 'positive' ? 'reports.statementsPositive' : 'reports.statementsNegative', item.count) }}<br>
              {{ processedTrends[(item._id?.categoryHash as string)] ? processedTrends[(item._id?.categoryHash as string)].text : $t('reports.noTrend') }}
            </q-tooltip>
          </div>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ item.title }}</q-item-label>
          <q-item-label caption :lines="open.includes(index) ? undefined : 1">{{ item.caption.join(', ')
            }}</q-item-label>
          <div v-if="open.includes(index)" class="q-mt-md">
            <hy-statement-chart :slope="trends && item._id ? trends[item._id.categoryHash] : {slope:null, percentageChange: null}" :data="childrenDeveloment[index] ? childrenDeveloment[index].development : null" :loading="childrenLoading.includes(index)" />
          </div>
        </q-item-section>
        <q-item-section side>
          <q-btn round size="sm" color="hy-primary" icon="search" @click.stop="showReviews = item"></q-btn>
        </q-item-section>
      </q-item>
    </q-list>
    <hy-review-list :item="showReviews" :type="type" @hide="showReviews = null" />
  </div>
  
</template>
<script lang="ts">
import { ReportFilter, StatementsItem } from '@/types';
import { defineComponent, PropType } from 'vue';
import HyReviewList from '@/components/Report/ReviewList.vue';
import HyStatementChart from '@/components/Report/StatementChart.vue';
import { useReportStore } from '@/stores/report';

export default defineComponent({
  components: {
    HyReviewList,
    HyStatementChart
  },
  props: {
    items: {
      type: Array as PropType<StatementsItem[]>,
      required: true
    },
    type: {
      type: String as PropType<'positive' | 'negative'>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    filter: {
      type: [Object, null] as PropType<ReportFilter | null>,
      required: true
    },
    trends: {
      type: [Object, null] as PropType<Record<string, {percentageChange: number, slope: number}> | null>,
      required: true
    }
  },
  data() {
    return {
      open: [] as number[],
      showReviews: null as StatementsItem | null,
      childrenLoading: [] as number[],
      childrenDeveloment: {} as any,
      processedTrends: {} as Record<string, {icon: string, text: string}>
    }
  },
  methods: {
    async toggleItem(index: number, category: string) {
      if (this.open.includes(index)) {
        this.open = this.open.filter(i => i !== index)

      } else {
        this.open.push(index)
        this.childrenLoading.push(index)

        if (!this.childrenDeveloment[index]) {
          this.childrenDeveloment[index] = await useReportStore().fetchStatementDevelopment(
            this.filter as ReportFilter, 
            category,
            this.type
          )
        }

        this.childrenLoading = this.childrenLoading.filter(i => i !== index)
      }
    }
  },
  watch: {
    trends: {
      deep: true,
      handler() {

        this.processedTrends = {}

        this.items.forEach((item) => {
          const val = {
            icon: 'remove',
            text: this.$t('reports.trendNoData'),
            raw: this.trends ? this.trends[item._id?.categoryHash as string].percentageChange : 'nix'
          }
          const trend = this.trends ? this.trends[item._id?.categoryHash as string].percentageChange : null

          if (trend === undefined || trend === null) {
            return;
          } else if (trend > .1) {
            val.icon = 'trending_up'
            // val.text = `${this.$t('reports.trendUp')} (+${this.$n(trend, 'percent')})`
            val.text = `${this.$t('reports.trendUp')}`
          } else if (trend < -.1) {
            val.icon = 'trending_down'
            // val.text = `${this.$t('reports.trendDown')} (${this.$n(trend, 'percent')})`
            val.text = `${this.$t('reports.trendDown')}`
          } else {
            val.icon = 'trending_flat'
            // val.text = `${this.$t('reports.trendFlat')} (${this.$n(trend, 'percent')})`
            val.text = `${this.$t('reports.trendFlat')}`
          }

          this.processedTrends[(item._id?.categoryHash as string)] = val
        })
      }
    },
    filter: {
      deep: true,
      handler () {
        this.open = []
        this.childrenLoading = []
        this.childrenDeveloment = {}
      }
    }
  }
})
</script>
<style lang="sass" scoped>
@import '@/styles/variables.sass'
.item-count
  min-width: 100px
  text-align: right
  svg
    width: 20px
    height: 20px
    fill: $hy-text-secondary--light
    cursor: inherit
    font-size: inherit
    display: inline-flex
    align-items: center
    justify-content: center
    vertical-align: middle
    
  div
    text-align: right
    width: 100%
    
    .q-icon 
      // opacity: .75
  &:hover
    color: $hy-text-primary--light!important
    svg
      fill: $hy-text-primary--light
      // opacity: 1
</style>