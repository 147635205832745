import { defineStore } from "pinia";
import { FilterOption, Insights, InsightsReviewResponse, ReportFilter } from "@/types";
import axios from 'axios'
import { format, subDays } from "date-fns";

const resolveFilter = (filter: ReportFilter): any => {

  const resolved: any = {}

  if (filter.range.type === 'custom') {
    resolved.from = format(filter.range.value[0], 'yyyy-MM-dd')
    resolved.to = format(filter.range.value[1], 'yyyy-MM-dd')
  } else {
    //@ts-ignore
    resolved.from = format(subDays(new Date(), (filter.range.value).split('_')[0]), 'yyyy-MM-dd')
    resolved.to = format(new Date(), 'yyyy-MM-dd')
  }

  resolved.tenant = filter.tenant

  return resolved
}

export const useReportStore = defineStore('report', {
  state() {
    return {
      availableCompetitors: [] as FilterOption[],
    }
  },
  actions: {
    async setCompetitorOptions(tenantId: string): Promise<void>  {
      try {
        this.availableCompetitors = (await axios.get('/competition/options?tenantId=' + tenantId)).data
      } catch (e) {
        console.error(e)
      }
    },
    async fetchInsights(filter: ReportFilter, locale: string): Promise<Insights> {

      const filterResolved = resolveFilter(filter)

      // await axios.get('/genai/gen-statements', {params: filterResolved})

      const res = await axios.get('/insights', {
        params: Object.assign({}, filterResolved, {locale: locale})
      })
      
      return res.data
    },

    async generateSummary(filter: ReportFilter, locale: string, payload: any): Promise<string> {
      
      const filterResolved = resolveFilter(filter)

      const res = await axios.post('/genai/gen-summary', 
        Object.assign({}, filterResolved, {locale: locale, payload: payload})
      )
      
      return res.data.summary
    },
    async fetchReviews (reviewIds: string[], locale: string, search?: string): Promise<InsightsReviewResponse> {
      const res = await axios.get('/insights/reviews', {
        params: { 
          reviewIds: reviewIds.join(','),
          locale: locale,
          search: search
         }
      })

      return res.data
    },
    async fetchStatementDevelopment (filter: ReportFilter, category: string, sentiment: 'positive' | 'negative'): Promise<InsightsReviewResponse> {
      
      const filterResolved = resolveFilter(filter)

      const res = await axios.get('/insights/statements-development', {
        params: Object.assign({}, filterResolved, {category, sentimentString: sentiment})
      })

      return res.data
    },
  }
})