import { defineStore } from 'pinia'
import { franc } from 'franc-min'
import axios from 'axios'
import { marked } from 'marked'

interface TranslationState {
  currentLocale: 'en' | 'de'
  animationDuration: number
  loading: Record<string, boolean>
  showing: Record<string, boolean>
  text: Record<string, string>
}

export const useTranslationStore = defineStore('translation', {
  state(): TranslationState {
    return {
      currentLocale: 'en' as 'en' | 'de',
      animationDuration: 300,
      loading: {},
      showing: {},
      text: {}
    }
  },
  actions: {
    reset(key: string | 'all') {
      this.animationDuration = 0

      if (key === 'all') {
        this.showing = {}
        this.text = {}
      }

      this.showing[key] = false
      this.text[key] = ''
    },
    toggle(key: string, locale: string, content: string) {

      if (this.showing[key]) {
        this.reset(key)
        return
      }
      this.showing[key] = true

      this.generate(key, content, locale)
    },

    isAvailable(key: string, review: any, locale: string): boolean {
      let text: string = review.message, lang: string

      switch (key) {
        case 'response':
          text = review.responses[0].message;
          lang = franc(text)

          if (
            (lang !== 'deu' && locale === 'de') ||
            (lang !== 'eng' && locale === 'en')
          ) {
            return true
          }
          break;
        case 'draft':
          text = review.responseDraft?.text as string;
          lang = franc(text)

          if (
            (lang !== 'deu' && locale === 'de') ||
            (lang !== 'eng' && locale === 'en')
          ) {
            return true
          }
          break;
        default:

          if (!review.message) {
            return false
          }

          // strip out the positive/negative tags for booking.com
          if (review.platformName === 'booking-readonly') {
            text = text.replace('positive:', '').replace('negative:', '');
          }

          
          // return true if the backend-detected language is set and not the same as the current locale
          if (review.languageInstructions) {
            return review.languageInstructions.detectedLanguage.iso2 !== locale
          }
          
          // detect language client side
          lang = franc(text)

          if (
            (lang !== 'deu' && locale === 'de') ||
            (lang !== 'eng' && locale === 'en')
          ) {
            return true
          }
          break;

      }
      return false;

    },
    async generate(key: string, text: string, locale: string): Promise<void> {

      this.loading[key] = true
      try {
        const res = await axios.post('/genai/gen-translation', { text, locale })

        // convert markdon to html and sript all tagy except <p> and <br>
        this.text[key] = (await marked.parse(res.data.translation, { pedantic: true })).replace(/(<\/?(?:p|br)[^>]*>)|<[^>]+>/ig, '$1')

        this.loading[key] = false
      }
      catch (e) {
        this.text[key] = 'Error: Translation failed. Please try again later.'
        console.error(e)
      }
    }
  }

})