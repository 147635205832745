<template>
<q-btn color="hy-primary" class="full-width ellipsis" :label="$t('interactions.btnPublish')"
              @click="confirm()" :disable="disable" />
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
export default defineComponent({
  props: {
    platform: {
      type: String as PropType<string>,
      required: true
    },
    content: {
      type: String as PropType<string>,
      required: true
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['publish'],
  methods: {
    confirm() {
      this.$q.dialog({
        class: 'hy-dialog',
        title: this.$t('app.confirm'),
        message: ['google', 'facebook'].includes(this.platform) ? this.$t('interactions.publish.api') : this.$t('interactions.publish.platform'),
        persistent: true,
        color: 'hy-primary',
        cancel: this.$t('app.cancel'),
        ok: this.$t('app.confirm'),
      }).onOk(() => {
        this.publish()
      })
    },
    publish() {
      
      switch (this.platform) {
        case 'google':
          this.publishViaApi()
          break;
        default:
          this.publishOnPlatform(this.platform)
          break;  
      }
    },
    publishViaApi() {
      this.$emit('publish')
    },
    async publishOnPlatform(platform: string) {
      
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(this.content);
        return;
      } else {
        await navigator.clipboard.writeText(this.content)
        console.log('Async: Copying to clipboard was successful!');
      }

      if (platform === 'booking-readonly') {
        window.open('https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/reviews.html')
      } else if (platform === 'expedia-readonly') {
        window.open('https://www.expediapartnercentral.com')
      }

    },
    fallbackCopyTextToClipboard(text: string) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }
  }
})
</script>